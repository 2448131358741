@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Solvay';
    src:  url('assets/KlavikaRegular.woff') format('woff');
  }

  .solvayLogo{
    width: 250px;
    height:inherit;
  }

  input, button, label, h2, a,.solvay{
    font-family: 'Solvay', sans-serif;
    /*color: white;*/
  }

  .vertical-align {
      display: flex;
      align-items: center;
  }

.topnav {
  overflow: hidden;
}

.topnav a {
  float: left;
  color: black;
  text-align: center;
  padding-top: 20px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: lightsteelblue;
  color: white;
}

.bg {
  background-image: url('src/assets/background_blue_gradient.png');
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;;
  max-height: 100%;
  overflow: auto;
}

.bg_home {
  background-image: url('src/assets/homepage_all_in_one.png');
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-height: 100%;
}

.bg_mountain {
  background-image: url('src/assets/background_with_mountain.png');
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 100%;
  overflow: auto;
}

html, body { height: 100%; margin: 0; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
